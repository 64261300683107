import React from 'react'; 
import { Link } from 'react-router-dom'; 
const Message = () => {
 
 

    return (
       <>
       <div className='message_blk'>
        <div className='d-flex'>
          <div className='message_img chairman orderd-1'>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/chairman1.png"></img>
            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/message-btn.gif' className='message-btn' />
          </div>
          <div className='message_content orderd-2'>
            <h5>H.G. Alexios Mar Eusebius</h5>
            <h6>CHAIRMAN</h6>
            <p>His Grace Dr. Stephanos Mar Theodosius of Blessed Memory<br></br> Former Metropolitan of the Diocese of Kolkata<br />God chooses great leaders to lead his people, and to be good stewards in His kingdom.  His Grace Dr. Stephanos Mar Theodosius, the Bishop of Kolkata Diocese of the Indian Orthodox Church, was one such visionary leader. He was born on October 2, 1924 to Mr. Chacko Kurian and Mrs. Mariamma Chacko of the Kayyalath family in Pathamoothom, Kottayam, Kerala. </p>
            <button className='button'><Link to="/Chairman"> View All </Link></button>
            
          </div>
          <div className='message_img founder orderd-3'>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/patrons1.png"></img>
            <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/message-btn.gif' className='message-btn' />
          </div>
          <div className='message_content orderd-6'>
            <h5>Dr. Stephanos Mar Theodosius</h5>
            <h6>FOUNDER</h6>
            <p>Welcome all of you to a new academic year that can take you to higher levels of learning experience and happiness. Start on the new session with dedication and decisiveness to excel not only in academics but also in character. Intelligence plus character- that is the goal of true education.</p>
            
          </div>
          <div className='message_img patron orderd-5'>
            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/founder1.png"></img><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/message-btn.gif' className='message-btn' />
          </div>
          <div className='message_content orderd-4'>
            <h5>St. Gregorios Of Parumala</h5>
            <h6>Our Patron</h6>
            <p>Metropolitan Geevarghese Mar Gregorios of Blessed Memory<br />Former Metropolitan of the Diocese of Niranam <br />Saint Gregorios of Parumala, is the first declared saint of Malankara Orthodox Church of Kerala, India. Popularly known as Bishop of Parumala, shines in the cloud of witnesses as a bright luminary emitting rays of hope and courage to millions in their sufferings and struggles. Mar Gregorios was born on 15th June 1848 to Kochu Mathai and Mariam of Pallathetta family in the Chathuruthy house in Mulamthuruthy. He was called by the name ‘Kochaippora’ and was given the baptismal name ‘Geevarghese’. Kochaippora had two brothers and two sisters: Kurian, Eli, Mariam and Varkey. Kochaippora was the youngest of all and was therefore the most beloved to everyone. Unfortunately, his mother passed away when he was only two years old. His eldest sister Mariam became all that a mother meant for him. Mariam was already married and had a child of Kochaippora’s age.</p>
            <button className='button'><Link to="/OurPatron"> View All </Link></button>
          </div>
        </div>
       </div>
       </>
    );
};

export default Message;
