import React, {useEffect } from 'react'; 
import { Link } from 'react-router-dom'
const Chairman = () => {
    useEffect(() => {
        document.title = 'Our Chairman - H.G. Alexios Mar Eusebius';  
    }, []);  
  return (
     <>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>School</li>
                 <li>Our Chairman</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Our Chairman </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="clearfix">
                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/chairman1.png" alt="" className="col-lg-3 col-md-4 float-md-end  left-img img-fluid"/>
                    <p>His Grace Dr. Stephanos Mar Theodosius of Blessed Memory</p>
                    <p>Former Metropolitan of the Diocese of Kolkata</p>
<p>God chooses great leaders to lead his people, and to be good stewards in His kingdom.  His Grace Dr. Stephanos Mar Theodosius, the Bishop of Kolkata Diocese of the Indian Orthodox Church, was one such visionary leader. He was born on October 2, 1924 to Mr. Chacko Kurian and Mrs. Mariamma Chacko of the Kayyalath family in Pathamoothom, Kottayam, Kerala. </p>
<p>Mr. K. K. Punnose, as he was named by his parents, was ordained as Deacon and Priest by the then Catholicos, His Holiness Geevarghese II.  Fr. K. K. Punnose started his services as Parish Priest in Jabalpur, Madhya Pradesh in 1952.  </p>
<p>In 1972, he was appointed as the Director of the newly founded St. Thomas Orthodox Church Mission at Bhilai, a post he whole heartedly accepted, with a burning zeal to serve the downtrodden. </p>
<p>In 1975, with utmost humility and absolute dedication to God, he accepted the decision of the Church to consecrate him as a Bishop. Consequently, he was consecrated at Niranam Valiyapalli as Bishop Stephanos Mar Theodosios. He became the first Bishop of the Diocese of Madras and later, in 1979, when the Diocese of Kolkata was formed, became its first Bishop.</p>
<p>With the creation of the Kolkata Diocese, the activities of St. Thomas Mission became more extensive and increased rapidly. Educational institutions, health service centers, village development centers, balagrams (Children’s homes), rehabilitation centers, ashrams and convents were successfully established in the states from Madhya Pradesh to Nagaland. </p>
<p>His Grace’s dedicated service, persistent hard work and love for education produced a good number of educational institutions across his pastoral domain. More than two dozen schools and colleges as well as a number of balwadis were established in Central and Eastern India, with the aim of uplifting and providing the best education especially to the underprivileged sections of the society. The establishment of St. Thomas College, at Bhilai and the College of Engineering and Technology also at Bhilai, needs special mention. Yet another landmark achievement was the setting up of the first Orthodox Theological Seminary outside Kerala, which was later shifted to Nagpur.</p>
<p>His Grace, whose missionary zeal and vision contributed to the golden era of mission in the Orthodox church, left for his heavenly abode on 5 November 2007, during an Episcopal visit to Muscat. His valuable and innumerable contributions to the mission field, remains as a glorious legacy of this great visionary and Man of God. </p>
<p>Grant him Eternal rest, O Lord, and let your perpetual light shine upon him. Through your mercy may his soul rest in peace.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default Chairman

