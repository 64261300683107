import Home from'./Pages/Home' 
import { BrowserRouter, Routes, Route } from "react-router-dom"; 
import OurSchool from'./Pages/OurSchool' 
import SchoolTiming from'./Pages/SchoolTiming' 
import PrayerAnthem from'./Pages/PrayerAnthem' 
import OurPatron from'./Pages/OurPatron' 
import CareerGuidance from'./Pages/CareerGuidance' 
import Faculty from'./Pages/Faculty' 
import OurPrincipal from'./Pages/OurPrincipal' 
import Hod from'./Pages/Hod' 
import MissionVision from'./Pages/MissionVision' 
import OurChurch from'./Pages/OurChurch'  
import FeeDetails from'./Pages/FeeDetails'  
import StudentCorner from'./Pages/StudentCorner' 
import SubjectStudy from'./Pages/SubjectStudy' 
import Tc from'./Pages/Tc' 
import ActivityCorner from'./Pages/ActivityCorner' 
import SamplePaper from'./Pages/SamplePaper' 
import Syllabus from'./Pages/Syllabus' 
import Booklist from'./Pages/Booklist' 
import WorkingCommittee from'./Pages/WorkingCommittee'
import Facilities from'./Pages/Facilities'
import ClassRoom from'./Pages/ClassRoom'
import TinkeringLab from'./Pages/TinkeringLab'
 import PlayGround from'./Pages/PlayGround'
import Certificates from'./Pages/Certificates'
import Laboratories from'./Pages/Laboratories'
import Scholarship from'./Pages/Scholarship'
import BusRoute from'./Pages/BusRoute'
import Admission from'./Pages/Admission'
import Magazine from'./Pages/Magazine'
import Achievement from'./Pages/Achievement'
import Counselling from'./Pages/Counselling'
import ErpSoftware from'./Pages/ErpSoftware'
import Pphelp from'./Pages/Pphelp' 
import VideoGallery from'./Pages/VideoGallery' 
import EMagazine from'./Pages/EMagazine' 
import Gallery from'./Pages/Gallery' 
import SubGallery from'./Pages/SubGallery'
import BuildingProgram from'./Pages/BuildingProgram'
import SubBuildingProgram from'./Pages/SubBuildingProgram'
import News from'./Pages/News'
import Activity from'./Pages/Activity'
import Events from'./Pages/Events'
 import Toppers from'./Pages/Toppers'
 import ToppersXII from'./Pages/ToppersXII'
 import Library from'./Pages/Library'
import Header from './Component/Header' 
import Footer from './Component/Footer'
import Chairman from './Pages/Chairman'
function App() { 
  return (
      <>
      <BrowserRouter>
      <Header/>
      <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/OurSchool" element={<OurSchool/>} />
      <Route path="/SchoolTiming" element={<SchoolTiming/>} />
      <Route path="/PrayerAnthem" element={<PrayerAnthem/>} />
      <Route path="/OurPatron" element={<OurPatron/>} />
      <Route path="/CareerGuidance" element={<CareerGuidance/>} />
      <Route path="/Faculty" element={<Faculty/>} />
      <Route path="/Hod" element={<Hod/>} />
      <Route path="/MissionVision" element={<MissionVision/>} />
      <Route path="/OurChurch" element={<OurChurch/>} /> 
      <Route path="/FeeDetails" element={<FeeDetails/>} /> 
      <Route path="/StudentCorner" element={<StudentCorner/>} /> 
      <Route path="/SubjectStudy" element={<SubjectStudy/>} /> 
      <Route path="/Tc" element={<Tc/>} /> 
      <Route path="/ActivityCorner" element={<ActivityCorner/>} /> 
      <Route path="/SamplePaper" element={<SamplePaper/>} /> 
      <Route path="/Syllabus" element={<Syllabus/>} /> 
      <Route path="/Booklist" element={<Booklist/>} /> 
      <Route path="/OurPrincipal" element={<OurPrincipal/>} /> 
      <Route path="/WorkingCommittee" element={<WorkingCommittee/>} /> 
      <Route path="/Facilities" element={<Facilities/>} /> 
      <Route path="/ClassRoom" element={<ClassRoom/>} /> 
      <Route path="/TinkeringLab" element={<TinkeringLab/>} /> 
      <Route path="/PlayGround" element={<PlayGround/>} /> 
      <Route path="/Certificates" element={<Certificates/>} /> 
      <Route path="/Laboratories" element={<Laboratories/>} /> 
      <Route path="/BusRoute" element={<BusRoute/>} /> 
      <Route path="/Scholarship" element={<Scholarship/>} /> 
      <Route path="/Admission" element={<Admission/>} /> 
       <Route path="/Magazine" element={<Magazine/>} /> 
        <Route path="/Achievement" element={<Achievement/>} /> 
        <Route path="/Counselling" element={<Counselling/>} /> 
        <Route path="/ErpSoftware" element={<ErpSoftware/>} /> 
        <Route path="/Pphelp" element={<Pphelp/>} /> 
         <Route path="/VideoGallery" element={<VideoGallery/>} /> 
         <Route path="/EMagazine" element={<EMagazine/>} /> 
       <Route path="/Gallery" element={<Gallery/>} /> 
       <Route path="/SubGallery" element={<SubGallery/>} />
        <Route path="/BuildingProgram" element={<BuildingProgram/>} />
        <Route path="/SubBuildingProgram" element={<SubBuildingProgram/>} />
        <Route path="/News" element={<News/>} />
        <Route path="/Events" element={<Events/>} />
        <Route path="/Activity" element={<Activity/>} />
        <Route path="/Toppers" element={<Toppers/>} />
        <Route path="/ToppersXII" element={<ToppersXII/>} />
        <Route path="/Library" element={<Library/>} />
        <Route path="/Chairman" element={<Chairman/>} />
      </Routes>
      <Footer />
     </BrowserRouter>
      </>
  )
}

export default App;
