import React, { useEffect, useState } from "react";
import { getGallery } from "../Service/Api";
import { Link } from 'react-router-dom' 
import Slider from "react-slick";
const Gallery = () => {
  const [data, setData] = useState([]);
 const [loading, setLoading] = useState(true); 
   useEffect(() => {
   const fetchData = async () => {
        try{
          const galleryData = await getGallery();
          setData(galleryData);
        }catch (error) {
          console.log("Error fetching gallery data:",error)
        }finally{
          setLoading(false)
        }
     
     };
   fetchData();
   }, []);

   const empty = [
    { title: "M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel ",  img: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/gal-1.jpg" },   
    { title: "M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel ",  img: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/gal-1.jpg" },   
    { title: "M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel ",  img: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/gal-1.jpg" },
    { title: "M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel ",  img: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/gal-1.jpg" },   
    { title: "M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel ",  img: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/gal-1.jpg" },
    { title: "M.G.M. (acronym for Mar Gregorios Memorial) Higher Secondary School, Bokaro Steel ",  img: "https://d280nq1n4mqyso.cloudfront.net/api/image/MGMB/public/Images/gal-1.jpg" } 
    
    
  ];
  var settings = { 
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false, 
    adaptiveHeight: true,
    responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 3,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll:1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow:1,
            slidesToScroll: 1, 
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
     <> 
     <div className="row">
     <Slider {...settings}>
     {data.length > 0 ? (data.map((item,index) => (
                   
      <div className="item"key={index}>
           <div className="galleryimg">
             <div className="galleryimgbox">
                 <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} className="img-fluid" alt="MGM bokaro"/>
                   <div className="gallerydesc">
                       <Link to="/Gallery" className=""><i className="bi bi-zoom-in"></i></Link>
                       <h6>{item.title}</h6> 
                   </div>
               </div>
             </div>
   </div>

))) : (empty.map((item) => (
<div className="item" key={item.id}>
<div className="galleryimg">
 <div className="galleryimgbox">
     <img src={item.img} className="img-fluid" alt="MGM bokaro"/>
       <div className="gallerydesc">
           <Link to="/Gallery" className=""><i className="bi bi-zoom-in"></i></Link>
           <h6>{item.title}</h6> 
       </div>
   </div>
 </div>
</div>

)))


}
       </Slider>
     </div>
     </>
  )
}

export default Gallery